// Import Swiper React components
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Wrapper from "../../wrappers/talentsWrapper";

import Talent from "./Talent";

import imageOne from "../../assets/talents-image/image-1.jpg";
import imageTwo from "../../assets/talents-image/image-2.jpg";
import imageThree from "../../assets/talents-image/image-3.jpg";
import imageFour from "../../assets/talents-image/image-4.jpg";
import imageFive from "../../assets/talents-image/image-5.jpg";
import imageSix from "../../assets/talents-image/image-6.jpg";
import imageSeven from "../../assets/talents-image/image-7.jpg";
import imageEight from "../../assets/talents-image/image-8.jpg";
import imageNine from "../../assets/talents-image/image-9.jpg";
import imageTen from "../../assets/talents-image/image-10.jpg";

const sliderSettings = {
  arrows: false,
  dots: false,
  infinite: true,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  centerMode: true,

  // arrows: false,
  // dots: false,
  // infinite: true,
  // slidesToShow: 5,
  // slidesToScroll: 1,

  // variableWidth: true,

  // autoplay: true,
  // autoplaySpeed: 0,
  // speed: 2000,
  // pauseOnHover: false,
  // cssEase: "ease",

  responsive: [
    {
      breakpoint: 1300,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1070,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 940,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: false,
      },
    },
    {
      breakpoint: 630,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
      },
    },
  ],
};

const Talents = () => {
  return (
    <Wrapper>
      <div>
        <Slider {...sliderSettings}>
          <div style={{ width: 100 }} className="slide">
            <Talent
              src={imageOne}
              name="Rajesh Kumar"
              position="Web developer"
            />
          </div>
          <div style={{ width: 100 }} className="slide">
            <Talent
              src={imageTwo}
              name="Priya Patel"
              position="Software Engineer"
            />
          </div>
          <div style={{ width: 100 }} className="slide">
            <Talent
              src={imageThree}
              name="Dr. Sunil Patel"
              position="Project Manager"
            />
          </div>
          <div style={{ width: 100 }} className="slide">
            <Talent
              src={imageFour}
              name="Pooja Gupta"
              position="Java developer"
            />
          </div>
          <div style={{ width: 100 }} className="slide">
            <Talent
              src={imageFive}
              name="Manoj Verma"
              position="Digital Marketer"
            />
          </div>
          <div style={{ width: 100 }} className="slide">
            <Talent
              src={imageSix}
              name="Ritu Tiwari"
              position="Android Developer"
            />
          </div>

          <div style={{ width: 100 }} className="slide">
            <Talent
              src={imageSeven}
              name="Ajay Choudhary"
              position="IOS Developer"
            />
          </div>
          <div style={{ width: 100 }} className="slide">
            <Talent
              src={imageEight}
              name="Nisha Joshi"
              position="Data Analyst"
            />
          </div>
          <div style={{ width: 100 }} className="slide">
            <Talent
              src={imageNine}
              name="Prakash Joshi"
              position="C++ Developer"
            />
          </div>
          <div style={{ width: 100 }} className="slide">
            <Talent src={imageTen} name=" Smita Verma" position="AI Expert" />
          </div>
        </Slider>
      </div>
    </Wrapper>
  );
};

export default Talents;
