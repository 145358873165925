import { useRef } from "react";

import Wrapper from "../wrappers/formRawWrapper";

const FormRow = ({
  label,
  name,
  type,
  value,
  placeholder,
  onChange,
  refer,
}) => {
  return (
    <Wrapper>
      <div className="input-container">
        <label htmlFor={name}>{label}</label>
        <input
          type={type}
          name={name}
          id={name}
          className="main-input input input-field"
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          required
          ref={refer}
        />
      </div>
    </Wrapper>
  );
};
export default FormRow;
