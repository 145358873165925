import { useRef, useState } from "react";

import { Form, redirect, useNavigate, useNavigation } from "react-router-dom";

import emailjs from "@emailjs/browser";
import axios from "axios";

import FormRow from "../components/FormRow";

import Wrapper from "../wrappers/contactPageWrapper";

import { toast } from "react-toastify";

import { BiEnvelope } from "react-icons/bi";

export const action = async ({ request }) => {
  const formData = await request.formData();
  const data = Object.fromEntries(formData);

  console.log("Contact action is execiuted");

  try {
    const dataFromServer = await axios.post(
      "https://srivango-server-two.onrender.com/send-email",
      data
    );

    // toast.success("Login success, login page", { autoClose: 3000 });
    toast.success(
      "Your form has been successfully submitted. Our team will get in touch with you shortly.",
      { autoClose: 2000 }
    );
    console.log(dataFromServer);
    return redirect("/");
  } catch (error) {
    console.log(error);
    error.message = error?.response?.data?.message;
    toast.error(error?.response?.data?.message);
    return error;
  }
};

const ContactPage = () => {
  const navigation = useNavigation();
  const isSubmitting = navigation.state === "submitting";

  return (
    <Wrapper>
      <div className="form-container">
        <div className="top-text-container">
          <h1 className="form-h1">Find Your Perfect Match</h1>
          <p className="form-p">
            Fill out the Form and Unlock Talented Candidates
          </p>
        </div>
        <div className="inside-form-container">
          <Form method="POST">
            <FormRow
              name="name"
              type="text"
              placeholder="Your Name"
              label="Name"
            />
            <FormRow
              name="email"
              type="email"
              placeholder="Your Email"
              label="Email"
            />
            <FormRow
              name="location"
              type="text"
              placeholder="Your Location"
              label="Location"
            />
            <input name="subject" type="hidden" value="Client request" />
            <div className="form-footer">
              <p>After Submitting, We'll Connect with You for the Next Step!</p>
            </div>
            <button
              type="submit"
              className="submit-btn"
              disabled={isSubmitting}
            >
              {`${isSubmitting ? "Submitting..." : "Submit"}`}
            </button>
          </Form>
        </div>

        <aside className="side-content">
          <div className="side-in-content">
            <h3>Contact</h3>
            <div className="email-and-icon">
              <span className="email-icon">
                {" "}
                <BiEnvelope />{" "}
              </span>
              <a href="mailto:sales@srivango.com">sales@srivango.com</a>
            </div>
          </div>
        </aside>
      </div>
    </Wrapper>
  );
};

export default ContactPage;
