import React from "react";

import Wrapper from "../../wrappers/moreWrapper";

const More = () => {
  return (
    <Wrapper>
      <section className="section">
        <h2 className="text-h2">Explore Our Proficient Skillsets</h2>
        <div className="more-container">
          <div class="accordion more-inside-container" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button slide-title"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Mobile Development
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <ul className="ul text-dark">
                    <li>Andriod Developers</li>
                    <li>IOS Developers</li>
                    <li>Andriod App Developers</li>
                    <li>App Store Developers</li>
                    <li>Mobile UI Designers</li>
                    <li>Flutter Developers</li>
                    <li>React Native Developers</li>
                    <li>Kotlin Developers</li>
                    <li>Swift Developers</li>
                    <li>iPhone App Developers</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed slide-title"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Web Development
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <ul className="ul">
                    <li>React.js Developers</li>
                    <li>Angular Developers</li>
                    <li>UI UX Designers</li>
                    <li>CSS Developers</li>
                    <li>JavaScript Developers</li>
                    <li>Vue.js Developers</li>
                    <li>API Developers</li>
                    <li>Node.js Developers</li>
                    <li>ASP.NET Developers</li>
                    <li>Meteor Developers</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="heading_3">
                <button
                  class="accordion-button collapsed slide-title"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  UI/UX Designers
                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="heading_3"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <ul className="ul">
                    <li>UI Designers</li>
                    <li>UX Designers</li>
                    <li>Web Designers</li>
                    <li>Mobile UI Designers</li>
                    <li>Illustrator</li>
                    <li>Digital Product Designers</li>
                    <li>Designers</li>
                    <li>Sketch Designers</li>
                    <li>Creative Directors</li>
                    <li>Product Designers</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="heading_4">
                <button
                  class="accordion-button collapsed slide-title"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  Programming Languages
                </button>
              </h2>
              <div
                id="collapseFour"
                class="accordion-collapse collapse"
                aria-labelledby="heading_4"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <ul className="ul">
                    <li>JavaScript Developers</li>
                    <li>Python Developers</li>
                    <li>C++ Developers</li>
                    <li>PHP Developers</li>
                    <li>Ruby Developers</li>
                    <li>Rust Developers</li>
                    <li>Dart Developers</li>
                    <li>C# Developers</li>
                    <li>Swift Developers</li>
                    <li>Java Developers</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="heading_5">
                <button
                  class="accordion-button collapsed slide-title"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  Desktop Development
                </button>
              </h2>
              <div
                id="collapseFive"
                class="accordion-collapse collapse"
                aria-labelledby="heading_5"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <ul className="ul">
                    <li>PyQt Developers</li>
                    <li>DevExpress Developers</li>
                    <li>Qt Developers</li>
                    <li>.NET Developers</li>
                    <li>.NET Core Developers</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="heading_6">
                <button
                  class="accordion-button collapsed slide-title"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                >
                  Data Science & AI
                </button>
              </h2>
              <div
                id="collapseSix"
                class="accordion-collapse collapse"
                aria-labelledby="heading_6"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <ul className="ul">
                    <li>Data Scientists</li>
                    <li>Machine Learning Engineers</li>
                    <li>Data Analysts</li>
                    <li>Algorithm Developers</li>
                    <li>MATALAB Developers</li>
                    <li>Power BI Developers</li>
                    <li>Open AI Developers</li>
                    <li>Excel Experts</li>
                    <li>Microsoft Access Developers</li>
                    <li>Python Developers</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="heading_7">
                <button
                  class="accordion-button collapsed slide-title"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSeven"
                  aria-expanded="false"
                  aria-controls="collapseSeven"
                >
                  Finance & Business Consulting
                </button>
              </h2>
              <div
                id="collapseSeven"
                class="accordion-collapse collapse"
                aria-labelledby="heading_7"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <ul className="ul">
                    <li>Financial Analysts</li>
                    <li>Pricing Consultants</li>
                    <li>Startup Funding Consultants</li>
                    <li>Cash Flow Consultants</li>
                    <li>M$A Consultants</li>
                    <li>Venture Capital Consultants</li>
                    <li>Fractional CFOs</li>
                    <li>Small Business Consultants</li>
                    <li>Pitch Deck Consultants</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="heading_8">
                <button
                  class="accordion-button collapsed slide-title"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEight"
                  aria-expanded="false"
                  aria-controls="collapseEight"
                >
                  Product & Project Management
                </button>
              </h2>
              <div
                id="collapseEight"
                class="accordion-collapse collapse"
                aria-labelledby="heading_8"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <ul className="ul">
                    <li>Remote Product Managers</li>
                    <li>Technical Product Managers</li>
                    <li>Digital Product Managers</li>
                    <li>Project Managers</li>
                    <li>Technical Business Analysts</li>
                    <li>App Product Managers</li>
                    <li>Web Project Managers</li>
                    <li>Product Management Consultants</li>
                    <li>Contract Product Managers</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="heading_9">
                <button
                  class="accordion-button collapsed slide-title"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseNine"
                  aria-expanded="false"
                  aria-controls="collapseNine"
                >
                  E-commerce & CRM Platforms
                </button>
              </h2>
              <div
                id="collapseNine"
                class="accordion-collapse collapse"
                aria-labelledby="heading_9"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <ul className="ul">
                    <li>E-commerce Developers</li>
                    <li>WooCommerce Developers</li>
                    <li>Magento Developers</li>
                    <li>Shopify Developers</li>
                    <li>Shopify API Developers</li>
                    <li>OpenCart Developers</li>
                    <li>Salesforce Commerce Cloud Developers</li>
                    <li>BigCommerce Developers</li>
                    <li>Google AdWords Developers</li>
                    <li>Salesforce Developers</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="heading_10">
                <button
                  class="accordion-button collapsed slide-title"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTen"
                  aria-expanded="false"
                  aria-controls="collapseTen"
                >
                  AR/VR & Game Development
                </button>
              </h2>
              <div
                id="collapseTen"
                class="accordion-collapse collapse"
                aria-labelledby="heading_10"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <ul className="ul">
                    <li>Game Developers</li>
                    <li>Augmented Reality Developers</li>
                    <li>Augmented Reality Designers</li>
                    <li>Virtual Reality Developers</li>
                    <li>3D Animators</li>
                    <li>Mobile Game Designers</li>
                    <li>Computer Vision Developers</li>
                    <li>2D Animators</li>
                    <li>Unity or Unity3D Developers</li>
                    <li>Three.js Developers</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="heading_11">
                <button
                  class="accordion-button collapsed slide-title"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEleven"
                  aria-expanded="false"
                  aria-controls="collapseEleven"
                >
                  CMS Platforms
                </button>
              </h2>
              <div
                id="collapseEleven"
                class="accordion-collapse collapse"
                aria-labelledby="heading_11"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <ul className="ul">
                    <li>CMS Developers</li>
                    <li>WordPress Designers</li>
                    <li> WordPress Theme Developers</li>
                    <li>WordPress Developers</li>
                    <li>Joomla Developers</li>
                    <li> Drupal Developers</li>
                    <li>SharePoint Developers</li>
                    <li>Webflow Designers</li>
                    <li>Elementor Developers</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Wrapper>
  );
};

export default More;
