import { styled } from "styled-components";

const Wrapper = styled.section`
  /* display: block; */
  margin-top: 12rem;
  width: 100%;
  background: #f8f8f8;
  padding: 2rem 1rem;
  display: flex;
  justify-content: center;
  font-family: "Poppins", sans-serif;
  width: 100vw;
  .section-featured {
    padding: 2.2rem 0 3.2rem 0;
    width: 100vw;
  }

  .container {
    /* 1140px */
    max-width: 120rem;
    padding: 0 3.2rem;
    margin: 0 auto;
  }

  .heading-featured-in {
    font-size: 1.8rem;
    text-transform: uppercase;
    letter-spacing: 0.75px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 2.4rem;
    color: #888;
  }

  .logos {
    display: flex;
    justify-content: space-around;
  }

  .logo-image {
    height: 3.2rem;
    /* filter: brightness(0); */
    /* opacity: 50%; */

    margin: 0 15px;
  }
`;

export default Wrapper;
