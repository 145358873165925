import { styled } from "styled-components";

const Wrapper = styled.section`
  font-family: "Montserrat", sans-serif;
  .input-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 20px;
    /* width: 80%; */
    label {
      font-size: 16px;
      margin-bottom: 3px;
      font-weight: 500;
    }
  }
  .main-input {
    padding: 0.5rem 1rem;

    border-radius: 6px;
    /* background-color: ; */
    border: none;
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
  }

  @media (max-width: 400px) {
    .form-container {
      width: 350px;
    }
  }
`;

export default Wrapper;
