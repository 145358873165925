import React from "react";

import Wrapper from "../../wrappers/hireEasyWrapper";

const HireEasy = (props) => {
  return (
    <Wrapper>
      <div>
        <section className="section">
          <h2 className="text-h2">Easy To Hire</h2>
          <div className="steps-container">
            <div className="steps-inside-container">
              <div className="step-container">
                <div className="step">1</div>
                <div className="description-container">
                  <h3 className="text-h3">
                   Engage with one of Our Industry Experts
                  </h3>
                  <div className="text-description">
                  A specialist from our team will collaborate with you to grasp your objectives, technical requirements, and team dynamics.
                  </div>
                </div>
              </div>
              <div className="step-container">
                <div className="step">2</div>
                <div>
                  <h3 className="text-h3">Collaborate with Carefully Chosen Talent</h3>
                  <div className="text-description">
                  In a matter of days, we'll connect you with the ideal talent for your project. The average matching time is less than 36 hours.
                  </div>
                </div>
              </div>
              <div className="step-container">
                <div className="step">3</div>
                <div>
                  <h3 className="text-h3">Ensuring the Perfect Match, Guaranteed</h3>
                  <div className="text-description">
                  Collaborate with your new team member on a trial basis (compensation only if you're satisfied), ensuring you onboard the right individuals for the task.
                </div>
              </div>
            </div>
          </div>
          </div>
        </section>
      </div>
    </Wrapper>
  );
};

export default HireEasy;
