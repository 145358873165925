import { styled } from "styled-components";

const Wrapper = styled.section`
  font-family: "Montserrat", sans-serif;
  footer {
    background-color: #212121;
    color: #fff;

    margin-left: 0;
    margin-right: 0;
  }

  .footer-inside-container {
    margin: 0 auto;
    max-width: 100%;
    padding: 36px 24px;
    width: 1272px;
  }
  .footer-inside-container {
    padding-bottom: 80px;
    padding-top: 80px;
  }

  @media (min-width: 1024px) {
    .footer-inside-container {
      padding-left: 36px;
      padding-right: 36px;
    }
  }

  /* ######################### */
  .items-container {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    justify-content: space-evenly;
    margin-bottom: 48px;
  }

  .item {
    flex: 1;
    min-width: 255px;
  }

  @media (min-width: 1200px) {
    .item {
      min-width: auto;
    }
  }

  @media (min-width: 1024px) {
    .item {
      min-width: 449px;
    }
  }
  @media (min-width: 768px) {
    .item {
      min-width: 329px;
    }
  }
  @media (min-width: 600px) {
    .item {
      min-width: 245px;
    }
  }
  @media (min-width: 500px) {
    .item {
      min-width: 195px;
    }
  }

  /* ############################# */
  .inside-item h3 {
    color: #fff;

    border-bottom: 1px solid hsla(0, 0%, 100%, 0.2);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 16px;
    padding-bottom: 16px;
    white-space: nowrap;
  }

  @media (max-width: 490px) {
    .item {
      text-align: center;
    }
  }

  .list-item {
    a {
      color: #fff;
      font-size: 17px;
      font-weight: 300;
      line-height: 28px;
      text-decoration: none;
      cursor: pointer;
      transition: all 0.2s ease;
    }
    a:hover {
      color: #af7c7c;
    }
  }

  .list-item:not(:last-child) {
    margin-bottom: 10px;
  }

  /* ####################################### */

  .list-item:not(:last-child) {
    margin-bottom: 32px;
  }

  .logo-and-text-container {
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.2);
    border-top: 1px solid hsla(0, 0%, 100%, 0.2);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 32px 0;
    text-align: center;
  }

  .logo-container {
    color: #fff;
    padding-bottom: 4px;
  }

  .logo-container {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }

  @media (min-width: 768px) {
    .logo-container {
      padding-bottom: 0;
    }
  }

  @media (min-width: 768px) {
    .logo-container {
      width: auto;
    }
  }

  /* ####################### */
  .inside-logo-container {
    align-items: baseline;
    color: #455065;
    display: flex;
    flex-shrink: 0;
    font-size: 29px;
    line-height: 29px;
    max-width: 100%;
  }

  .inside-logo-container {
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 0;
  }

  /* ####################### */
  .logo-container-inside {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 29px;
    line-height: 29px;
    max-width: 100%;
    font-family: "Poppins", sans-serif;

    .first-letter {
      font-size: 40px;
    }

    h1 {
      position: relative;
    }
    span {
      position: absolute;
      font-size: 20px;
      top: 30px;
    }
  }

  .logo-img {
    width: 40px;
    height: 40px;
  }

  /* ################################ */
  .simple-text {
    align-items: baseline;
    display: flex;
    margin-left: 16px;
    min-height: 32px;
  }
  @media (max-width: 767px) {
    .simple-text {
      justify-content: center;
      margin: 14px 0 0;
    }
  }

  .separator:before {
    border-color: inherit;
    opacity: 0.2;
  }

  .separator:before {
    border-left: 1px solid #c4c6ca;
    content: "";
    display: inline-block;
    height: 32px;
    margin-right: 16px;
    margin-left: 10px;
    vertical-align: -20%;
  }

  @media (max-width: 800px) {
    .separator:before {
      display: none;
    }

    .logo-container-inside {
      margin-right: 10px;
    }
  }

  .simple-text-inside {
    font-size: 16px;
    letter-spacing: normal;
    line-height: 24px;
  }
  .simple-text-inside {
    color: #fafafa;
    display: inline-block;
    font-size: 17px;
    font-weight: 400;
    letter-spacing: -0.6px;
    line-height: 19px;
    padding-left: 10px;

    span {
      font-size: 13px;
      line-height: 13px;
      vertical-align: -30%;
    }
  }

  @media (max-width: 767px) {
    .simple-text-inside {
      display: block;
    }
  }

  /* ###################### */
  .social-media-container {
    align-items: center;
    display: flex;
    justify-content: flex-start;

    ul {
      display: flex;

      li:not(:last-child) {
        margin-right: 16px;
      }
    }
  }

  @media (max-width: 767px) {
    .social-media-container {
      justify-content: center;
      margin-top: 24px;
      width: 100%;
    }
  }

  .media {
    background: transparent;
    border: 1px solid hsla(0, 0%, 100%, 0.2);
    color: #fff;
    opacity: 0.9;
    transition: opacity 0.2s;

    align-items: center;
    border-radius: 50%;
    display: flex;
    height: 40px;
    justify-content: center;
    width: 40px;
  }

  /* #################################### */

  .copy-right-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    font-size: 14px;
    justify-content: center;
    line-height: 20px;
  }

  @media (min-width: 768px) {
    .copy-right-container {
      flex-direction: row;
    }
  }

  /* ######################## */

  .copy-right-text {
    display: inline;
    white-space: nowrap;
    margin-top: 20px;
  }

  @media (min-width: 768px) {
    .copy-right-text {
      margin-bottom: 0;
      margin-right: 16px;
    }
  }
`;

export default Wrapper;
