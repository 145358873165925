import Wrapper from "../../wrappers/testimonialWrapper";

import React from "react";

import Quote from "../../assets/Quote";

import customerOne from "../../assets/customers images/customer-1.jpg";
import customerTwo from "../../assets/customers images/customer-2.jpg";
import customerThree from "../../assets/customers images/customer-3.jpg";
import customerFour from "../../assets/customers images/customer-4.jpg";

const Testimonial = () => {
  return (
    <Wrapper id="testimonials">
      <div className="testimonial-container">
        <section className="testimonial-inside-container">
          <h2 className="testimonial-h2">Testimonials From Our Clients</h2>
          <div></div>
          <div className="quote-container">
            <div className="quote-inside-container">
              <blockquote className="blockquote">
                <div className="inside-blockquote-container">
                  <Quote />
                  <p className="testimonial-text">
                    Srivango consistently exceeds our expectations for software
                    development. Their talented team understands our
                    requirements and delivers high-quality solutions on time.
                    Their professionalism, attention to detail, and proactive
                    communication make them an invaluable asset. We highly
                    recommend Srivango's services
                  </p>
                </div>
                <div className="img-and-client-container">
                  <img src={customerOne} alt="client" className="client-img" />
                  <div className="client-container">
                    <p className="client-name">Michael David</p>
                    <p className="client-location">Mumbai, India</p>
                  </div>
                </div>
              </blockquote>
            </div>
            <div className="quote-inside-container">
              <blockquote className="blockquote">
                <div className="inside-blockquote-container">
                  <Quote />
                  <p className="testimonial-text">
                    Choosing Srivango as our e-commerce solution provider was
                    the best decision. Their robust platform and expertise in
                    online retail helped us establish a successful store.
                    Srivango's user-friendly interface, seamless integrations,
                    and powerful features empower us to drive sales and deliver
                    an exceptional shopping experience. We highly endorse
                    Srivango for thriving in the e-commerce industry.
                  </p>
                </div>
                <div className="img-and-client-container">
                  <img src={customerTwo} alt="client" className="client-img" />
                  <div className="client-container">
                    <p className="client-name">Thomas Richard</p>
                    <p className="client-location">London, United Kingdom</p>
                  </div>
                </div>
              </blockquote>
            </div>
            <div className="quote-inside-container">
              <blockquote className="blockquote">
                <div className="inside-blockquote-container">
                  <Quote />
                  <p className="testimonial-text">
                    Srivango revolutionized our customer support system. Their
                    state-of-the-art technology and personalized approach enable
                    our team to provide exceptional assistance. Srivango's
                    platform streamlines the process, improving customer
                    satisfaction and efficiency. Their responsive team goes the
                    extra mile. We are delighted to have Srivango as our trusted
                    partner.
                  </p>
                </div>
                <div className="img-and-client-container">
                  <img
                    src={customerThree}
                    alt="client"
                    className="client-img"
                  />
                  <div className="client-container">
                    <p className="client-name">William Edward</p>
                    <p className="client-location">Paris, France</p>
                  </div>
                </div>
              </blockquote>
            </div>
            <div className="quote-inside-container last-testimonial">
              <blockquote className="blockquote last-blockquote">
                <div className="inside-blockquote-container">
                  <Quote />
                  <p className="testimonial-text">
                    Srivango has transformed our digital marketing efforts.
                    Their comprehensive strategies and expert guidance resulted
                    in increased online visibility and customer engagement.
                    Working with Srivango has been a pleasure, and we highly
                    appreciate their dedication to delivering outstanding
                    results. They are a valuable partner for any business
                    looking to thrive in the digital landscape.
                  </p>
                </div>
                <div className="img-and-client-container">
                  <img src={customerFour} alt="client" className="client-img" />
                  <div className="client-container">
                    <p className="client-name">David Brand</p>
                    <p className="client-location">
                      New York City, United States
                    </p>
                  </div>
                </div>
              </blockquote>
            </div>
          </div>
        </section>
      </div>
    </Wrapper>
  );
};

export default Testimonial;
