import { styled } from "styled-components";

const Wrapper = styled.section`
  padding: 0 20px;
  padding-top: 30px;
  padding-bottom: 40px;
  /* padding-left: 4rem; */
  background: #f8f8f8;
  width: 100vw;

  .slide {
    height: 200px;
    margin: 0 80px;
  }
`;

export default Wrapper;
