import React from "react";
import Wrapper from "../../wrappers/expertisesWrapper";

import iconOne from "../../assets/expertise icons/image-1.svg";
import iconTwo from "../../assets/expertise icons/image-2.svg";
import iconThree from "../../assets/expertise icons/image-3.svg";
import iconFour from "../../assets/expertise icons/image-4.svg";

import imageOne from "../../assets/expertise images/image-1.jpg";
import imageTwo from "../../assets/expertise images/image-2.jpg";
import imageThree from "../../assets/expertise images/image-3.jpg";

const Expertises = () => {
  return (
    <Wrapper>
      <div>
        <section className="section">
          <div className="texts-container">
            <h2 className="text-h2">Create Exceptional Teams, On Demand</h2>
            <p className="text-p">
              Software, BPO and Finance - Offshore your work to our people in
              India
            </p>
            <div className="texts-inside-container">
              <div className="text-container">
                <div className="text-inside-container">
                  <img src={iconOne} style={{ width: "6rem" }} alt="icon" />
                  <div className="text-in-inside-container">
                    <h2>Hire Faster</h2>
                    <p>
                       Efficiently Employ in Under 36 Hours. Easily Adapt Your Workforce, No Commitments Required. Enjoy Flexible Arrangements, from Hourly to Full-Time.
                    </p>
                  </div>
                </div>
              </div>
              <div className="text-container">
                <div className="text-inside-container">
                  <img src={iconTwo} style={{ width: "6rem" }} alt="icon" />
                  <div className="text-in-inside-container">
                    <h2> The Top Talent </h2>
                    <p>
                    Every candidate in the Srivango network undergoes rigorous testing and comprehensive vetting. Our highly selective process results in an exceptional trial-to-hire success rate of 80%.
                    </p>
                  </div>
                </div>
              </div>
              <div className="text-container">
                <div className="text-inside-container">
                  <img src={iconThree} style={{ width: "6rem" }} alt="icon" />
                  <div className="text-in-inside-container">
                    <h2>Pioneering the Future of Work</h2>
                    <p>
                    Our network is prepared to address the business challenges of the future by embracing advanced and specialized expertise, including fields like blockchain and AI.
                    </p>
                  </div>
                </div>
              </div>
              <div className="text-container">
                <div className="text-inside-container">
                  <img src={iconFour} style={{ width: "6rem" }} alt="icon" />
                  <div className="text-in-inside-container">
                    <h2>Elevated Beyond</h2>
                    <p>
                    Every consultant in our global network embodies steadfast integrity, the highest levels of professionalism, and outstanding communication abilities.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="images-container">
            <div className="images-inside-container">
              <div className="image-and-text">
                <div className="image-and-text-inside">
                  <div className="image-container">
                    <img className="expert-image" src={imageOne} alt="worker" />
                  </div>
                  <div className="text-container">
                    <a>Anil Verma</a>
                    <p>Software Developer</p>
                  </div>
                </div>
              </div>
              <div className="image-and-text second-element">
                <div className="image-and-text-inside">
                  <div className="image-container">
                    <img className="expert-image" src={imageTwo} alt="worker" />
                  </div>
                  <div className="text-container">
                    <a>Ritu Tiwari</a>
                    <p>Android Developer</p>
                  </div>
                </div>
              </div>
              <div className="image-and-text">
                <div className="image-and-text-inside">
                  <div className="image-container">
                    <img
                      className="expert-image"
                      src={imageThree}
                      alt="worker"
                    />
                  </div>
                  <div className="text-container">
                    <a>Rahul Sharma</a>
                    <p>Data Analyst</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Wrapper>
  );
};

export default Expertises;
