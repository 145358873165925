import React from "react";

import Wrapper from "../../wrappers/servicesWrapper";

// import designers from "../../assets/services-images/design.png";
// import imageTwo from "../../assets/services-images/image-2.svg";
// import imageOne from "../../assets/services-images/image-2.svg";
// import imageThree from "../../assets/services-images/image-3.svg";
// import imageFour from "../../assets/services-images/image-4.svg";
// import imageFive from "../../assets/services-images/image-5.svg";
// import imageSix from "../../assets/services-images/image-6.svg";
// import imageSeven from "../../assets/services-images/image-7.svg";
// import imageOne from "../../assets/services-images/image-1.svg"

import designers from '../../assets/services-images/designer.svg'
import productManager from '../../assets/services-images/projectManager.svg'
import project from '../../assets/services-images/project.svg'
import developer from '../../assets/services-images/developer.svg'
import finance from '../../assets/services-images/finance.svg'
import projectManager from '../../assets/services-images/product.svg'

const Services = () => {
  return (
    <Wrapper id="services">
      <header className="header-container">
        <h2 className="header-h2">You can do high quality hiring with us</h2>
      </header>
      <div className="description">
        You can get quality people quickly and helps to scale up your business
        faster.
      </div>
      <div className="lists">
        <div className="list">
          <img src={designers} alt="description" />
          <h3> Designers</h3>
          <p>
          Proficient UI, UX, Visual, and Interaction designers, in addition to a diverse array of illustrators, animators, and other professionals. 
          </p>
        </div>
        <div className="list">
          <img src={productManager} alt="description" />

          <h3>Product Managers</h3>
          <p>
          Experienced digital product managers and Scrum product owners with proficiency across various sectors such as banking, healthcare, e-commerce, and beyond.
          </p>
        </div>
        <div className="list">
          <img src={projectManager} alt="description" />
          <h3>Project Managers</h3>
          <p>
          Skilled digital and technical project managers, along with Scrum masters and others, who possess proficiency in a wide range of project management tools, frameworks, and methodologies.
          </p>
        </div>
        <div className="list">
          <img src={finance} alt="description" />
          <h3>Finance Experts</h3>
          <p>
      
          Professionals adept in financial modeling and valuation, startup funding, interim CFO responsibilities, and market sizing.
          </p>
        </div>

        <div className="list">
          <img src={developer} alt="description" />
          <h3>Developers</h3>
          <p>
          Experienced software engineers, programmers, and architects with proficiency spanning hundreds of technologies.
          </p>
        </div>
        <div className="list">
          <img src={project} alt="description" />

          <h3>Srivango® Projects</h3>
          <p>
          Srivango Project Consultants create organized teams of experienced specialists to address your most pressing business requirements.
          </p>
        </div>
      </div>
    </Wrapper>
  );
};

export default Services;
