import { styled } from "styled-components";

const Wrapper = styled.section`
  font-family: "Montserrat", sans-serif;
  width: 100%;
  margin: 0 30px;
  /* width: 200px;
  min-width: 150px; */

  /* .swiper-slide {
    width: 170px;
  } */
  /* margin: 0 70px; */
  .talent-container {
    border-radius: 20px;
    cursor: pointer;
    /* margin-right: 8px;
    min-width: 150px; */
    /* opacity: 0.5; */
    position: relative;
    transition: opacity 0.5s;
  }

  .talent-container {
    background: #fff;
    font-size: 11px;
    font-weight: 400;
    width: 200px;
    transition: all 0.2s ease;
  }

  .talent-img-container {
    border-radius: 20px;
    width: 200px;
    height: 140px;
  }

  .talent-img {
    border-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    width: 100%;
    height: 100%;
  }

  .title-container {
    padding: 10px 10px;
    .talent-name {
      margin-bottom: 2px;
      color: #0b0563;
      font-size: 14px;
    }
    .position {
      color: #0b0563;
      font-size: 13px;
    }
  }

  .talent-container:hover {
    scale: calc(1.05);
    .title-container {
      background: #d1d1dd;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }
`;

export default Wrapper;
