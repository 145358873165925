import React from "react";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "swiper/css/scrollbar";

import Wrapper from "../../wrappers/companiesWrapper";

import ubc from "../../assets/brands/ucb.png";
import atrix from "../../assets/brands/atrix.png";
import movate from "../../assets/brands/Movate.jpg";
import rocket from "../../assets/brands/rocket.png";
import crestone from "../../assets/brands/crestone.png";
import datamatics from "../../assets/brands/datamatics.png";
import hdfc from "../../assets/brands/HDFC.png";
import indegen from "../../assets/brands/indegen.png";
import microland from "../../assets/brands/microland.jpg";
import qubix from "../../assets/brands/qubix.webp";
import quess from "../../assets/brands/quess.png";
import salad from "../../assets/brands/salad.png";
import tataComm from "../../assets/brands/tata-comm.png";
import tata from "../../assets/brands/tata.png";
import crestonTwo from "../../assets/brands/crestronTwo.png";

const sliderSettings = {
  arrows: false,
  dots: false,
  infinite: true,
  slidesToShow: 5,
  slidesToScroll: 1,

  variableWidth: true,

  autoplay: true,
  autoplaySpeed: 0,
  speed: 2000,
  pauseOnHover: false,
  cssEase: "ease",

  responsive: [
    {
      breakpoint: 1300,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1070,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 940,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
      },
    },
    {
      breakpoint: 630,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
      },
    },
  ],
};

const Companies = () => {
  return (
    <Wrapper>
      <div className="company-container">
        <section class="section-featured">
          <div class="container">
            <h2 class="heading-featured-in">Brands trusted Srivango</h2>
            <div>
              <Slider {...sliderSettings}>
                <div>
                  <img src={tata} alt=" logo" className="logo-image" />
                </div>
                <div>
                  <img src={tataComm} alt=" logo" className="logo-image" />
                </div>
                <div>
                  <img src={rocket} alt=" logo" className="logo-image" />
                </div>
                <div>
                  <img src={hdfc} alt=" logo" className="logo-image" />
                </div>
                {/* <div >
                <img src={ubc} alt=" logo" className="logo-image" />
              </div> */}
                <div>
                  <img src={atrix} alt=" logo" className="logo-image" />
                </div>
                <div>
                  <img src={crestonTwo} alt=" logo" className="logo-image" />
                </div>
                {/* <div >
                <img src={movate} alt=" logo" className="logo-image" />
              </div>
              <div >
                <img src={datamatics} alt=" logo" className="logo-image" />
              </div> */}
                <div>
                  <img src={indegen} alt=" logo" className="logo-image" />
                </div>
                {/* <div >
                <img src={microland} alt=" logo" className="logo-image" />
              </div> */}
                <div>
                  <img src={qubix} alt=" logo" className="logo-image" />
                </div>
                {/* <div >
                <img src={quess} alt=" logo" className="logo-image" />
              </div>
              <div >
                <img src={salad} alt=" logo" className="logo-image" />
              </div> */}
                {/* <div>
                  <img src={crestone} alt=" logo" className="logo-image" />
                </div> */}
              </Slider>
            </div>
          </div>
        </section>
      </div>
    </Wrapper>
  );
};

export default Companies;
