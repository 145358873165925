import { styled } from "styled-components";

const Wrapper = styled.section`
  font-family: "Poppins", sans-serif;
  padding: 0 20px;
  padding-bottom: 40px;
  background: #f8f8f8;
  padding-top: 2rem;

  .text-h2 {
    font-size: 44px;
    line-height: 56px;
    margin: 0 0 24px;
    font-weight: 300;
  }
  .text-p {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 48px;
  }

  @media (max-width: 650px) {
    .text-h2 {
      font-size: 34px;
      text-align: center;
    }
    .text-p {
      font-weight: 400;
      font-size: 14px;
      margin-bottom: 48px;
    }
  }
  @media (max-width: 500px) {
    .text-h2 {
      font-size: 28px;
      text-align: center;
      line-height: 40px;
    }
    .text-p {
      font-weight: 400;
      font-size: 12px;
      margin-bottom: 48px;
    }
  }

  .section {
    display: flex;
    /* justify-content: center; */
    /* justify-content: space-between; */
    margin-top: 20px;
    padding: 0 9rem;
  }
  @media (max-width: 769px) {
    .section {
      padding: 0 3rem;
    }
  }
  @media (max-width: 650px) {
    .section {
      padding: 0;
    }
  }
  .texts-inside-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
    margin-top: 20px;
  }
  .text-container {
    /* max-width: 500px; */
    width: 300px;
  }

  .text-in-inside-container {
    img {
      margin-bottom: 38px;
    }

    h2 {
      font-size: 22px;
      line-height: 28px;
      margin-bottom: 24px;
      font-weight: 400;
      color: #0e034d;
    }
    p {
      font-size: 16px;
      line-height: 24px;
      color: #0e034d;
      font-weight: 400;
    }
  }

  @media (max-width: 1170px) {
    .texts-inside-container {
      display: grid;
      /* grid-template-columns: 1fr; */
      justify-content: center;
    }
    .images-container {
      display: none;
    }
  }

  @media (max-width: 769px) {
    .texts-inside-container {
      display: grid;
      grid-template-columns: 1fr;
      justify-content: center;
      text-align: center;
    }
    .text-h2,
    .text-p {
      text-align: center;
    }
    .text-container {
      margin: 0 auto;
      /* max-width: 500px; */
      /* width: 300px; */
      width: 100%;
    }
  }

  .text-container {
    a {
      font-size: 13px;
      font-weight: 500;
      color: #0e034d;
    }

    p {
      font-size: 14px;
    }
  }

  /* ##################################3 */

  .images-container {
    /* position: relative; */
    margin-left: 18rem;
  }

  .images-inside-container {
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }

  .image-and-text-inside {
    position: relative;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
    width: 224px;
    /* width: auto; */
    background: #fafafa;
    border-radius: 10px;
    border-top-right-radius: 20px;
  }

  .text-container {
    padding: 4px 10px;
  }
  .image-and-text {
    border-top-right-radius: 20px;
  }

  .second-element {
    margin-right: -20px;
  }

  /* ########################### */

  .expert-image {
    cursor: pointer;
    object-fit: cover;
  }

  .expert-image {
    height: 160px;
    width: 224px;
  }
  .expert-image {
    align-items: center;
    background: #d8d9dc;
    display: flex;
    justify-content: center;
    margin: 0;
    overflow: hidden;
    position: relative;
    border-top-right-radius: 20px;
  }
`;

export default Wrapper;
