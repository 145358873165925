import React from "react";

import { Link } from "react-scroll";
import mainlogo from "../../assets/mainlogo.png";

import Wrapper from "../../wrappers/footerWrapper";

const Footer = () => {
  return (
    <Wrapper>
      <footer id="footer">
        <div className="footer-inside-container">
          <div className="items-container">
            <div className="item">
              <div className="inside-item">
                <h3>On-Demand Talent</h3>
                <ul className="items-list">
                  <li className="list-item">
                    <a>Hire Developers</a>
                  </li>
                  <li className="list-item">
                    <a>Hire Designers</a>
                  </li>
                  <li className="list-item">
                    <a>Hire Finance Experts</a>
                  </li>
                  <li className="list-item">
                    <a>Hire Project Managers</a>
                  </li>
                  <li className="list-item">
                    <a>Hire Product Managers</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="item">
              <div className="inside-item">
                <h3>Management Consulting</h3>
                <ul className="items-list">
                  <li className="list-item">
                    <a>Strategic Consulting</a>
                  </li>
                  <li className="list-item">
                    <a>Consulting for People and Organizations</a>
                  </li>
                  <li className="list-item">
                    <a>onsulting for Innovation and Customer Experience</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="item">
              <div className="inside-item">
                <h3>Contact</h3>
                <ul className="items-list">
                  <li className="list-item">
                    <a href="mailto:sales@srivango.com">sales@srivango.com</a>
                  </li>
                  <li className="list-item">
                    <a>
                      46/4, Hosur Rd, Kudlu Gate, HSR Extension, Bengaluru,
                      Karnataka, India
                    </a>
                  </li>
                  {/* <li className="list-item">
                    <a>FAQ</a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>

          <div className="logo-and-text-container">
            <div className="logo-container">
              <div className="logo-in">
                <div className="inside-logo-container">
                  <Link
                    className="logo-container-inside"
                    to="home"
                    spy={true}
                    smooth={true}
                    offset={-65}
                    duration={500}
                  >
                    <img
                      src={mainlogo}
                      alt="main navigation logo"
                      className="logo-img"
                    />
                    <h1>
                      <small className="first-letter">S</small>rivango
                      <span>&#174;</span>
                    </h1>
                  </Link>

                  <div>
                    <div className="simple-text">
                      <span className="separator"></span>
                      <div>
                        <div className="simple-text-inside">
                           Access the World's Premier Talent, Instantly
                          <span>®</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="social-media-container">
              <ul>
                <li>
                  <a
                    className="media"
                    href="https://in.linkedin.com/company/srivango"
                  >
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 17 16"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M.215 16h3.643V5.204H.215V16zM2.06 0C.814 0 0 .806 0 1.865 0 2.903.792 3.73 2.013 3.73h.025c1.27 0 2.06-.827 2.06-1.865C4.075.805 3.308 0 2.06 0zM17 9.808V16h-3.645v-5.777c0-1.45-.526-2.44-1.847-2.44-1.007 0-1.603.667-1.87 1.313-.095.23-.12.553-.12.874V16H5.875s.05-9.782 0-10.796h3.645v1.53c-.007.012-.018.023-.025.036h.025v-.037c.485-.734 1.348-1.782 3.284-1.782C15.2 4.951 17 6.493 17 9.808z"
                        fill="currentColor"
                        fill-rule="evenodd"
                      ></path>
                    </svg>
                  </a>
                </li>
                {/* <li>
                  <a className="media">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 21 17"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.862 4.84c0-.19-.01-.369-.018-.557A8.445 8.445 0 0 0 21 2.1a8.609 8.609 0 0 1-2.475.638A4.188 4.188 0 0 0 20.419.41a8.522 8.522 0 0 1-2.734.988A4.39 4.39 0 0 0 14.543 0a4.236 4.236 0 0 0-4.312 4.243c0 .335.035.661.113.979C6.76 5.002 3.584 3.245 1.462.59a4.295 4.295 0 0 0-.587 2.174c0 1.51.763 2.851 1.921 3.636A4.313 4.313 0 0 1 .84 5.835v.059a4.379 4.379 0 0 0 3.463 4.282 4.34 4.34 0 0 1-1.134.146 4.405 4.405 0 0 1-.814-.09 4.324 4.324 0 0 0 4.025 3.032A8.619 8.619 0 0 1 0 15.038 12.048 12.048 0 0 0 6.606 17c7.92 0 12.256-6.514 12.256-12.16"
                        fill="currentColor"
                        fill-rule="evenodd"
                      ></path>
                    </svg>
                  </a>
                </li> */}
                {/* <li>
                  <a className="media">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 11 23"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.271 23V11.488h3.892l.619-3.818h-4.51V5.756c0-1 .334-1.947 1.796-1.947H11V0H6.843C3.348 0 2.396 2.251 2.396 5.36v2.31H0v3.818h2.396V23H6.27z"
                        fill="currentColor"
                        fill-rule="evenodd"
                      ></path>
                    </svg>
                  </a>
                </li> */}
                {/* <li>
                  <a className="media">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 20 21"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g fill="currentColor" fill-rule="evenodd">
                        <path d="M18.225 15.204c0 2.17-1.68 3.932-3.744 3.932H5.519c-2.064 0-3.744-1.762-3.744-3.932v-9.41c0-2.168 1.68-3.93 3.744-3.93h8.962c2.064 0 3.744 1.762 3.744 3.93v9.41zM14.481 0H5.519C2.475 0 0 2.599 0 5.794v9.41C0 18.401 2.475 21 5.52 21h8.96c3.045 0 5.52-2.599 5.52-5.796v-9.41C20 2.599 17.525 0 14.48 0z"></path>
                        <path d="M10 14.931a3.936 3.936 0 0 1-3.933-3.932A3.938 3.938 0 0 1 10 7.064a3.937 3.937 0 0 1 3.933 3.935A3.936 3.936 0 0 1 10 14.93M10 5c-3.308 0-6 2.69-6 5.999A6.008 6.008 0 0 0 10 17c3.308 0 6-2.694 6-6.001A6.006 6.006 0 0 0 10 5M14.501 3c-.394 0-.783.158-1.06.438-.278.28-.441.666-.441 1.06 0 .395.163.782.44 1.062.278.277.667.44 1.061.44.395 0 .781-.163 1.059-.44.28-.28.44-.667.44-1.061A1.498 1.498 0 0 0 14.501 3"></path>
                      </g>
                    </svg>
                  </a>
                </li> */}
              </ul>
            </div>
          </div>

          <div className="copy-right-container">
            <p className="copy-right-text">Copyright 2023 Srivango Technologies</p>
          </div>
        </div>
      </footer>
    </Wrapper>
  );
};

export default Footer;
