import { useRef, useState } from "react";

import { Form, useNavigate, redirect, useNavigation } from "react-router-dom";

import emailjs from "@emailjs/browser";
import axios from "axios";

import FormRow from "../components/FormRow";

import { BiEnvelope } from "react-icons/bi";

import Wrapper from "../wrappers/contactPageWrapper";

import { toast } from "react-toastify";

export const action = async ({ request }) => {
  const formData = await request.formData();
  const data = Object.fromEntries(formData);

  console.log("Contact action is execiuted");

  try {
    const dataFromServer = await axios.post(
      "https://srivango-server-two.onrender.com/send-email/worker",
      // "http://localhost:8080/send-email/worker",
      data
    );

    // toast.success("Login success, login page", { autoClose: 3000 });
    toast.success(
      "Your form has been successfully submitted. Our team will get in touch with you shortly.",
      { autoClose: 2000 }
    );
    console.log(dataFromServer);
    return redirect("/");
  } catch (error) {
    console.log(error);
    error.message = error?.response?.data?.message;
    toast.error(error?.response?.data?.message);
    return error;
  }
};

const ApplyContactPage = () => {
  const navigation = useNavigation();
  const isSubmitting = navigation.state === "submitting";

  return (
    <Wrapper>
      <div className="form-container apply-form">
        <div className="top-text-container">
          <h1 className="form-h1">Find Your Perfect Match</h1>
          <p className="form-p">
            Apply Now and Unlock Exciting Job Opportunities
          </p>
        </div>
        <div className="inside-form-container">
          <Form method="POST" encType="multipart/form-data">
            <FormRow
              name="name"
              type="text"
              placeholder="Your Name"
              label="Name"
            />
            <FormRow
              name="email"
              type="email"
              placeholder="Your Email"
              label="Email"
            />
            <FormRow
              name="location"
              type="text"
              placeholder="Your Location"
              label="Location"
            />
            <FormRow
              name="skills"
              type="text"
              placeholder="Your Skills"
              label="Skills"
            />
            {/* <FormRow
              name="file"
              type="file"
              placeholder="Attach resume"
              label="Attach Your Resume"
              ref={locationValue}
              onChange={() => setLocationState(locationValue.current.value)}
            /> */}
            <input name="subject" type="hidden" value="Client request" />
            <div className="form-footer">
              <p>After Submitting, We'll Connect with You for the Next Step!</p>
            </div>
            <button
              type="submit"
              className="submit-btn"
              disabled={isSubmitting}
            >
              {`${isSubmitting ? "Submitting..." : "Submit"}`}
            </button>
          </Form>
        </div>

        <aside className="side-content">
          <div className="side-in-content">
            <h3>Contact</h3>
            <div className="email-and-icon">
              <span className="email-icon">
                {" "}
                <BiEnvelope />{" "}
              </span>
              <a href="mailto:sales@srivango.com">sales@srivango.com</a>
            </div>
          </div>
        </aside>
      </div>
    </Wrapper>
  );
};

export default ApplyContactPage;
