import { styled } from "styled-components";

const Wrapper = styled.section`
  background: #fff;

  font-family: "Poppins", sans-serif;
  margin-top: 4rem;
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  padding-left: 24px;
  padding-right: 24px;
  width: 1272px;
  margin-bottom: 96px;
  padding-top: 4rem;

  @media (min-width: 1024px) {
    /* background: #f0e8e8; */
    padding-left: 36px;
    padding-right: 36px;
  }

  /* ############################################ */
  .header-h2 {
    font-size: 45px;
    font-weight: 300;
    line-height: 48px;
    margin-bottom: 36px;
    text-align: center;
  }
  @media (max-width: 767px) {
    .header-h2 {
      font-size: 28px;
      line-height: 35px;
    }
  }

  /* ########################################### */
  .description {
    font-weight: 400;
    line-height: 24px;
    max-width: 555px;
    padding-bottom: 43px;
    text-align: center;
  }

  /* ############################################## */
  .lists {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1200px;
    overflow: hidden;
    /* background: #ff8787; */
  }
  @media (max-width: 767px) {
    .lists {
      margin: 0 -24px;
    }
  }

  /* ################################ */
  .list {
    flex: 1 2 324px;
    /* background: #ff8787; */
  }

  .list {
    align-items: stretch;
    border-left: 1px solid #d8d9dc;
    border-top: 1px solid #d8d9dc;
    color: #000;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: -1px 0 0 -1px;
    padding: 40px;
    text-decoration: none;

    img {
      display: block;
      height: 100px; // initial 64
      width: 100px; // initial 64
    }

    h3 {
      font-size: 20px;
      font-weight: 400;
      margin: 20px 0;
    }

    p {
      font-weight: 400;
      line-height: 24px;
      margin: 16px 0;
    }
  }

  .list:hover {
    background: #b9adad;
  }
`;

export default Wrapper;
