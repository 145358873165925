import React from "react";

import { useState } from "react";

import { Outlet, redirect } from "react-router-dom";

import { Link } from "react-scroll";

import { FiMenu } from "react-icons/fi";
import { FiX } from "react-icons/fi";

import mainlogo from "../../assets/mainlogo.png";
import Wrapper from "../../wrappers/mainNavWrapper";

const MainNav = () => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const toggleNavigationHandler = () => {
    setMobileNavOpen((prevState) => !prevState);

    // disabling scrolling if the mobile navigation is open
    document.querySelector("html").style.overflow = mobileNavOpen
      ? "auto"
      : "hidden";
  };

  const hideNavHandler = () => {
    setMobileNavOpen(false);
    document.querySelector("html").style.overflow = "auto";
  };

  const goToApply = () => {
    return redirect("/apply-contact");
  };

  return (
    <Wrapper>
      <nav className={`navigation ${mobileNavOpen ? "nav-open" : ""}`}>
        <div className="inside-navigation show-nav-bar">
          <div className="logo-container">
            <Link
              className="logo-container-inside"
              to="home"
              spy={true}
              smooth={true}
              offset={-65}
              duration={500}
            >
              <img
                src={mainlogo}
                alt="main navigation logo"
                className="logo-img"
              />
              <h1 className="text-underline">
                <small className="first-letter">S</small>rivango
                {/* <span>&#174;</span> */}
              </h1>
            </Link>
          </div>
          <div className="nav-links">
            <Link
              className="navLink first-link"
              to="home"
              spy={true}
              smooth={true}
              offset={-65}
              duration={500}
              onClick={hideNavHandler}
            >
              About
            </Link>
            <Link
              className="navLink"
              to="services"
              spy={true}
              smooth={true}
              offset={-65}
              duration={500}
              onClick={hideNavHandler}
            >
              Services
            </Link>
            <Link
              className="navLink"
              to="testimonials"
              spy={true}
              smooth={true}
              offset={-65}
              duration={500}
              onClick={hideNavHandler}
            >
              Testimonials
            </Link>
            <Link
              className="navLink"
              to="footer"
              spy={true}
              smooth={true}
              offset={-65}
              duration={500}
              onClick={hideNavHandler}
            >
              Contact
            </Link>
            {/* <Link
              className="navLink"
              to="section1"
              spy={true}
              smooth={true}
              offset={-65}
              duration={500}
              onClick={hideNavHandler}
            >
              Other page
            </Link> */}
          </div>

          {/* {` ${mobileNavOpen ? "open-ctas" : ""} ctas-container`} */}
          <ul className="ctas-container">
            <li className="cta-li">
              <a className="apply-btn" href="/apply-contact">
                Apply as a Talent
              </a>
            </li>
            <li className="cta-li">
              <a className="hire-btn" href="/contact">
                Hire Top Consultants
              </a>
            </li>
            {/* <li className="login-li">
              <a className="login-btn" href="/">
                Log In
              </a>
            </li> */}
          </ul>
          <button className="btn-mobile-nav">
            {!mobileNavOpen && (
              <FiMenu
                size={46}
                className="open-icn"
                onClick={toggleNavigationHandler}
              />
            )}
            {mobileNavOpen && (
              <FiX
                size={46}
                className="close-icn"
                onClick={toggleNavigationHandler}
              />
            )}
          </button>
        </div>
      </nav>

      {Outlet}
    </Wrapper>
  );
};

export default MainNav;
