import "./App.css";

import "swiper/swiper-bundle.css";

import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

import MainNav from "./components/1_main-navigation/MainNav";
import NavForContact from "./components/NavForContact";
import HeroSection from "./components/2_hero-section/HeroSection";
import Companies from "./components/2_hero-section/companies";
import Talents from "./components/3_talents-section/Talents";
import Services from "./components/4_services/Services";
import Testimonial from "./components/5_testimonial-section/Testimonial";
import Expertises from "./components/6_expertise-section/Expertises";
import HireEasy from "./components/7_hire-easy/HireEasy";
import More from "./components/8_more-section/More";
import Footer from "./components/9_footer-section/Footer";

import ContactPage from "./pages/ContactPage";
import ApplyContactPage from "./pages/ApplyContactPage";

import ErrorPage from "./pages/404";

import { action as contactAction } from "./pages/ContactPage";
import { action as applyAction } from "./pages/ApplyContactPage";

const router = createBrowserRouter([
  {
    path: "/",
    errorElement: <ErrorPage />,
    element: (
      <>
        <MainNav />,
        <HeroSection />,
        <Companies />,
        <Talents />,
        <Services />,
        <Expertises />,
        <HireEasy />,
        <Testimonial />,
        <More />,
        <Footer />
      </>
    ),
  },
  {
    path: "/contact",
    element: (
      <>
        <NavForContact />,
        <ContactPage />,
      </>
    ),
    action: contactAction,
  },
  {
    path: "/apply-contact",
    element: (
      <>
        <NavForContact />,
        <ApplyContactPage />,
      </>
    ),
    action: applyAction,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;

// function App() {
//   return (
//     <Router>
//       <Routes>
//         <Route
//           path="/"
//           element={
//             <>
//               <MainNav />
//               <HeroSection />
//               <Companies />
//               <Talents />
//               <Services />
//               <Expertises />
//               <HireEasy />
//               <Testimonial />
//               <More />
//               <Footer />
//             </>
//           }
//         />
//         <Route path="/contact" element={<ContactPage />} />
//         <Route path="*" element={<ErrorPage />} action={contactAction} />
//       </Routes>
//     </Router>
//   );
// }

// export default App;
