import { styled } from "styled-components";

const Wrapper = styled.section`
  font-family: "Montserrat", sans-serif;

  padding-bottom: 80px;
  padding-top: 80px;

  --tw-bg-opacity: 1;
  /* background-color: rgb(252 252 252 / var(--tw-bg-opacity)); */

  .section {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-left: 24px;
    padding-right: 24px;
    width: 1272px;
  }

  @media (min-width: 1024px) {
    .section {
      padding-left: 36px;
      padding-right: 36px;
    }
  }

  /* ######################## */
  .text-h2 {
    font-weight: 300;
    font-size: 28px;
    text-align: center;
    margin-bottom: 32px;
    --tw-text-opacity: 1;
    color: rgb(38 45 61 / var(--tw-text-opacity));
  }

  @media (min-width: 480px) {
    .text-h2 {
      font-size: 44px;
      margin-bottom: 48px;
    }
  }

  /* #################################### */
  .more-inside-container {
    --tw-shadow: 0 4px 8px #00000014;
    --tw-shadow-colored: 0 4px 8px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-inner-border-shadow, 0 0 #0000),
      var(--tw-shadow, 0 0 #0000);

    /* padding-bottom: 24px;
    padding-top: 24px;

    padding-left: 32px;
    padding-right: 32px; */

    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  }

  /* ################################## */
  .slide-title {
    font-size: 16px;
    margin: 5px 0;
  }

  .slide-title:hover {
    color: #170b86;
  }
  .ul {
    li:not(fist-child) {
      margin-top: 10px;
      font-size: 14px;
      cursor: pointer;
    }
    li:hover {
      text-decoration: underline;
      color: #7a5c5c;
    }
  }
`;

export default Wrapper;
