import { styled } from "styled-components";

const Wrapper = styled.section`
  font-family: "Montserrat", sans-serif;
  .navigation {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 10;
    font-size: 14px;
    font-weight: 400;
    height: 64px;
    /* background: #8ce99a; */
    /* background: rgb(153, 221, 255); */
    background: #fff;
    box-shadow: 2px 2px 2px #e3e3e3;
  }

  .inside-navigation {
    align-items: center;
    display: flex;
    /* flex: 1; */
    height: 100%;
    height: 64px;
    margin: 0 auto;
    max-width: 1272px;
    padding: 0 56px 0 16px;
    position: relative;
    width: 100%;
  }

  .nav-open .inside-navigation {
    align-items: flex-start;
    background: #fff;
    flex-direction: column;
    height: 100vh;
    overflow-y: auto;
    padding: 0 16px 16px;

    /* position: fixed;
      top: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.9);
      width: 50%;
      height: 100%;
      z-index: 3;

      display: flex;
      align-items: center; */
  }

  @media (min-width: 1200px) {
    .inside-navigation {
      padding: 0 36px;
    }
  }
  @media (min-width: 1050px) {
    .inside-navigation {
      padding-right: 16px;
    }
  }

  .logo-container {
    align-items: center;
    display: flex;
    height: 64px;
    min-height: 64px;
    position: relative;
  }

  @media (min-width: 768px) {
    .logo-container {
      margin-right: 40px;
    }
  }

  .logo-container-inside {
    display: flex;
    align-items: center;
    color: #000;
    font-size: 29px;
    line-height: 29px;
    max-width: 100%;
    font-family: "Poppins", sans-serif;
    text-decoration: none !important;

    .first-letter {
      font-size: 40px;
      text-decoration: none !important;
    }

    h1 {
      position: relative;
      text-decoration: none !important;
    }
    span {
      text-decoration: none !important;
      position: absolute;
      font-size: 20px;
      top: 30px;
    }
  }

  .logo-img {
    width: 40px;
    height: 40px;
  }

  /* ########################################### */

  .nav-links {
    display: none;
    position: relative;
    .navLink {
      align-items: center;
      color: #000;
      display: flex;
      flex-shrink: 0;
      font-size: 16px;
      font-weight: 500;
      height: 100%;
      position: relative;
      text-decoration: none;
      transition: all 0.5s ease;
    }
  }

  .nav-open .nav-links {
    /* padding-bottom: 16px;
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    /* height: auto; */
    width: 100%;
    margin-bottom: 24px;
  }
  .navLink:hover {
    scale: calc(1.02);
  }

  /* .active {
    background-color: #ecdedb;
    border-radius: 6px;
    padding: 8px 14px;
  } */

  .navLink {
    position: relative;
    cursor: pointer;
  }

  .nav-open .navLink {
    border-bottom: 1px solid #ebeced;
    font-size: 16px;
    line-height: 46px;
    margin-left: 0;
    width: 100%;
    height: 10%;
    margin-top: 20px;
  }

  .navLink::before {
    content: "";
    position: absolute;
    bottom: 1.5px;
    width: 0;
    height: 2px;
    background-color: #cc7a00;
    transition: width 0.3s ease-in-out;
  }
  @media (max-width: 1050px) {
    .navLink::before {
      display: none;
    }
  }

  .navLink:not(.active):hover::before {
    width: 100%;
    left: 0;
  }

  @media (min-width: 1050px) {
    .nav-links {
      display: flex;
      align-items: center;
      .navLink:not(.first-link) {
        margin-left: 16px;
      }
    }
  }

  @media (max-width: 1050px) {
    .back-to-home {
      margin-bottom: 30px;
      border: none !important;
      /* border-top: 3px solid #000; */
    }
  }

  /* ############################################## */
  .ctas-container {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    visibility: visible;
    z-index: 999999;
  }

  @media (max-width: 1050px) {
    .ctas-container {
      visibility: hidden;
    }
    .nav-open .ctas-container {
      visibility: visible;
      margin: 0 auto;
      margin-top: -7rem;
      flex-direction: column;
      gap: 20px;
    }
  }

  @media (min-width: 768px) {
    .ctas-container {
      margin-left: auto;
    }
  }
  .cta-li {
    a {
      width: 100%;
    }
  }
  @media (min-width: 1050px) {
    .cta-li {
      display: block;
    }
    .cta-li:not(:last-child) {
      margin-right: 16px;
    }
  }
  @media (max-width: 1050px) {
    .cta-li {
    }
  }
  .apply-btn {
    font-weight: 400;
    padding-left: 0;
    padding-right: 0;
  }

  .apply-btn {
    background: transparent;
    border-color: transparent;
    color: #000;
    transition-duration: 0.2s;
    transition-property: background-color, border-color, color;
  }
  .apply-btn {
    font-size: 14px;
    line-height: 20px;
    padding: 7px 24px;
  }
  .apply-btn,
  .hire-btn {
    -webkit-appearance: none;
    border: 1px solid transparent;
    border-radius: 3px;
    cursor: pointer;
    display: inline-block;
    font-weight: 600;
    outline: none;
    text-align: center;
    text-decoration: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
  }

  .hire-btn {
    background: #2e6edf;
    border-color: #2e6edf;
    color: #fff;
    transition-duration: 0.2s;
    transition-property: background-color, border-color, color;
    font-size: 14px;
    line-height: 20px;
    padding: 7px 24px;
    transition: all 0.2s ease;
  }

  .hire-btn:hover {
    background: #4e9257;
    border-color: #4e9257;
  }

  /* ######################################### */

  .login-li {
    display: block;
  }

  .login-btn {
    background: transparent;
    border-color: transparent;
    color: #000;
    font-weight: 500;
    transition-duration: 0.2s;
    transition-property: background-color, border-color, color;
  }

  /* ################################################3 */
  .btn-mobile-nav {
    display: none;
  }
  @media (max-width: 1050px) {
    .btn-mobile-nav {
      display: block;
    }
  }

  .btn-mobile-nav {
    margin: 0 0 0 auto;
    position: absolute;
    right: 16px;
    top: 15px;
  }
`;

export default Wrapper;
