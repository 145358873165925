import { styled } from "styled-components";

const Wrapper = styled.section`
  font-family: "Poppins", sans-serif;
  padding: 0 20px;
  padding-bottom: 40px;
  background: #fff;
  width: 100vw;
  .section {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-left: 24px;
    padding-right: 24px;
    width: 1272px;
  }

  @media (min-width: 1024px) {
    .section {
      padding-left: 36px;
      padding-right: 36px;
    }
  }

  .text-h2 {
    background-color: #fff;
    color: #000;
    display: flex;
    font-size: 28px;
    font-weight: 400;
    justify-content: center;
    line-height: 36px;
    margin-bottom: 48px;
    text-align: center;
    padding-top: 20px;
  }

  @media (min-width: 768px) {
    .text-h2 {
      font-size: 52px;
      font-weight: 300;
      line-height: 64px;
    }
  }

  /* #################################### */
  /* .TTvbhb5G {
    position: relative;
  } */
  .steps-inside-container {
    margin: -8px;
    width: calc(100% + 16px);
  }
  .steps-inside-container {
    align-items: stretch;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  @media (min-width: 1200px) {
    .steps-inside-container {
      margin: -16px;
      width: calc(100% + 32px);
    }
  }
  @media (min-width: 1024px) {
    .steps-inside-container {
      margin: -12px;
      width: calc(100% + 24px);
    }
  }
  @media (min-width: 768px) {
    .steps-inside-container {
      margin: -12px;
      width: calc(100% + 24px);
    }
  }

  /* #####################3 */
  .step-container {
    flex-basis: 100%;
    flex-shrink: 0;
    max-width: 100%;
    text-align: center;
    padding: 8px;
  }
  @media (max-width: 768px) {
    .step-container {
      text-align: left;
    }
  }
  .step-container {
    margin: 0;
  }
  .step-container {
    padding: 8px;
  }
  @media (min-width: 1024px) {
    .step-container {
      padding: 12px;
    }
  }

  @media (min-width: 768px) {
    .step-container {
      padding: 12px;
    }
  }
  @media (min-width: 768px) {
    .step-container {
      flex-basis: 33.3333333333%;
      flex-shrink: 0;
      max-width: 33.3333333333%;
    }
  }

  /* ############################### */

  .step {
    align-items: center;
    background: #fff;
    border: 1px solid #8d77dd;
    border-radius: 50%;
    color: #8d77dd;
    display: flex;
    display: inline-flex;
    flex: none;
    flex-direction: row;
    font-size: 20px;
    font-weight: 400;
    height: 48px;
    justify-content: center;
    line-height: 28px;
    width: 48px;
    z-index: 2;
  }

  @media (min-width: 768px) {
    .step {
      font-size: 32px;
      font-weight: 300;
      height: 64px;
      line-height: 60px;
      width: 64px;
    }
  }

  .text-h3 {
    background-color: #fff;
    color: #262d3d;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 16px;
    text-align: left;
  }

  @media (min-width: 768px) {
    .text-h3 {
      padding-left: 32px;
      padding-right: 32px;
    }
  }

  @media (min-width: 768px) {
    .text-h3 {
      padding-top: 48px;
    }
  }
  @media (max-width: 756px) {
    .text-h3 {
      margin-top: 20px;
    }
  }

  /* #################### */

  .text-description {
    background-color: #fff;
    color: #262d3d;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  @media (min-width: 768px) {
    .text-description {
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
      text-align: left;
    }
  }
`;

export default Wrapper;
