import { styled } from "styled-components";

const Wrapper = styled.section`
  font-family: "Poppins", sans-serif;
  padding: 0 4rem;
  /* background: #ecd6d6; */
  .hero-section-container {
    /* display: flex;
    flex-direction: column; */

    /* display: grid; */
    /* grid-template-columns: 1fr 1fr; */
    margin: 0 auto;
    max-width: 1200px;
    position: relative;
  }

  @media (min-width: 1024px) {
    .hero-section-container {
      display: block;
    }
  }
  @media (max-width: 1024px) {
    .hero-section-container {
      overflow: hidden;
    }
  }

  .hero-section-inside-container {
    margin: 56px auto 0;
    max-width: 640px;
    text-align: center;
    z-index: 1;
  }
  @media (min-width: 1024px) {
    .hero-section-inside-container {
      display: flex;

      flex-direction: column;
      /* display: grid; */
      /* grid-template-columns: 60% 40%; */
      justify-content: center;
      left: 0;
      margin: 0;
      max-width: 62%;
      /* position: absolute; */
      /* top: 85px; */
      padding-top: 9rem;
      text-align: left;
      z-index: 1;
    }
  }

  /* ######################################### */
  @media (max-width: 1024px) {
    .text-container {
      margin-top: 80px;
    }
  }
  .text-header {
    color: #262d3d;
    font-size: 24px;
    font-weight: 400;
    line-height: 35px;
    margin: 0 0 16px;
    padding: 0;
  }
  @media (min-width: 1201px) {
    .text-header {
      font-size: 44px;
      line-height: 56px;
    }
  }
  @media (min-width: 501px) {
    .text-header {
      font-size: 36px;
      font-weight: 300;
      line-height: 42px;
    }
  }

  /* ####################################### */
  .hero-description {
    color: #455065;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    margin: 0 0 16px;
    padding: 0;
  }
  @media (min-width: 501px) {
    .hero-description {
      font-size: 18px;
      font-weight: 300;
    }
  }

  /* ########################################### */
  .hero-cta-container {
    margin-top: 4rem;
    align-items: stretch;
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    width: 100%;
    a {
      background: #2e6edf;
      border-color: #2e6edf;
      border-radius: 3px;
      color: #fff;
      transition-duration: 0.2s;
      transition-property: background-color, border-color, color;

      font-size: 20px;
      line-height: 28px;
      padding: 11px 64px;
    }
    a:hover {
      background: #2f7238;
      border-color: #2f7238;
    }
  }
  @media (min-width: 426px) {
    .hero-cta-container {
      display: block;
      width: auto;
    }
  }

  /* ######################################### */

  .img-container {
    max-width: 50%;
    aspect-ratio: 4/3;
    position: absolute;
    right: 0;
    top: 85px;
    margin: 0;
    max-width: 42%;
    position: absolute;
    text-align: left;
    z-index: 1;

    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: contain;
    }
  }

  @media (max-width: 1024px) {
    .img-container {
      display: none;
    }
  }

  .hero-img {
    /* box-shadow: 3px 3px 3px #dab6b6; */
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.5));
  }
`;

export default Wrapper;
