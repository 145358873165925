import { styled } from "styled-components";

const Wrapper = styled.section`
  font-family: "Poppins", sans-serif;
  width: 100vw;

  .testimonial-container {
    background-color: #f8f8f8;
    padding: 48px 0 64px;
  }

  .testimonial-inside-container {
    margin-bottom: 0;
  }

  .testimonial-inside-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-left: 24px;
    padding-right: 24px;
    width: 1272px;
  }
  @media (min-width: 1024px) {
    .testimonial-inside-container {
      padding-left: 36px;
      padding-right: 36px;
    }
  }

  /* ############################# */
  .testimonial-h2 {
    margin-bottom: 24px;
    margin-top: 16px;
  }
  .testimonial-h2 {
    color: #262d3d;
    font-size: 28px;
    font-weight: 300;
    line-height: 36px;
    margin-bottom: 16px;
    text-align: center;
  }
  @media (min-width: 768px) {
    .testimonial-h2 {
      margin-bottom: 48px;
      margin-top: 24px;
      font-size: 44px;
      line-height: 56px;
    }
  }

  /* ################################ */

  .quote-container {
    margin: -8px;
    width: calc(100% + 16px);
  }
  .quote-container {
    align-items: stretch;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  @media (min-width: 1200px) {
    .quote-container {
      margin: -16px;
      width: calc(100% + 32px);
    }
  }

  @media (min-width: 1024px) {
    .quote-container {
      margin: -12px;
      width: calc(100% + 24px);
    }
  }

  @media (min-width: 768px) {
    .quote-container {
      margin: -12px;
      width: calc(100% + 24px);
    }
  }

  /* ############################################################### */

  .quote-inside-container {
    padding: 8px;

    flex-basis: 100%;
    flex-shrink: 0;
    max-width: 100%;
    margin: 0;
  }
  @media (min-width: 768px) {
    .quote-inside-container {
      flex-basis: 50%;
      flex-shrink: 0;
      max-width: 50%;

      padding: 12px;
    }
  }

  /* ########################################### */
  .blockquote {
    height: 100%;
    min-height: auto;
    width: 100%;
    align-items: stretch;
    color: #455065;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* min-height: 432px; */
    padding: 32px 24px 24px;

    background: #fff;
    border: 1px solid hsla(210, 5%, 93%, 0.5);
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  }
  blockquote,
  q {
    quotes: none;
  }
  @media (max-width: 1023px) {
    .blockquote {
      /* min-height: 0; */
    }
  }

  /* ########################################## */
  .testimonial-text {
    color: #455065;
    flex: 1;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    padding: 0 0 24px;
  }
  .img-and-client-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .client-img {
    border-radius: 50%;
    height: 55px;
    width: 55px;
    flex-shrink: 0;
    margin: 0 12px 8px 0;
  }

  .client-container {
    flex: 1;
    font-size: 14px;
    line-height: 14px;
    min-width: 150px;
  }

  .client-name {
    color: #455065;
    font-weight: 600;
    margin: 0;
  }

  .client-location {
    font-weight: 400;
    margin-top: 2px;
  }

  @media (max-width: 768px) {
    .last-testimonial {
      display: none;
    }
  }
`;

export default Wrapper;
