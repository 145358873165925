import React from "react";

import Wrapper from "../../wrappers/heroSectionWrapper";

import { Link } from "react-scroll";

import heroimage from "../../assets/talents-image/hero.png";

const HeroSection = () => {
  return (
    <Wrapper id="home">
      <div className="hero-section-container">
        <div className="hero-section-inside-container">
          <div className="text-container">
            <h1 className="text-header">
                Hire Top Talents with cost effectiveness {" "}
            </h1>
            <p className="hero-description">
            Srivango - Your Ultimate Destination for staffing IT Professionals and Engineering Professionals. Srivango talents can work remotely from Srivango's office or can work from Client office. Srivango specializes in deploying software developers, designers, finance experts, product managers, back office and project managers in both India and the USA. Distinguished enterprises and startups trust Srivango to hire outstanding consultants for their mission-critical projects. For more information, please contact us at sales@srivango.com 
            </p>
            <div className="hero-cta-container">
              <a
                href="/contact"
                style={{
                  textDecoration: "none",
                  width: "100%",
                  height: "100%",
                }}
              >
                Hire Top Consultants
              </a>
            </div>
          </div>
        </div>
        <div className="parent">
          <div className="img-container">
            <img src={heroimage} alt="consultant" className="hero-img" />
            <div className="img-shadow"></div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default HeroSection;
