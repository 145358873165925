import React from "react";

import Wrapper from "../../wrappers/talentWrapper";

const Talent = ({ src, name, position }) => {
  return (
    <Wrapper>
      <div className="talent-container">
        <div className="talent-img-container">
          <img src={src} alt="talent" className="talent-img" />
        </div>
        <div className="title-container">
          <h3 className="talent-name">{name}</h3>
          <div className="title-icon"></div>
          <p className="position">{position}</p>
        </div>
      </div>
    </Wrapper>
  );
};

export default Talent;
