import { styled } from "styled-components";

const Wrapper = styled.section`
  /* background-image: url(../assets/contact-bkg.svg); */
  /* background-size: contain; */
  background: #dee2e6;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;

  .form-container {
    margin-top: 20px;
    background: #adb5bd;
    padding: 4rem 2rem 3rem 2rem;
    width: 40rem;
    border-radius: 10px;
    box-shadow: 5px 5px 10px #868e96;
    position: relative;
  }
  .apply-form {
    margin-top: 10rem;
  }

  .top-text-container {
    margin-bottom: 30px;
    text-align: center;
  }

  .form-h1 {
  }
  .form-p {
    font-size: 14px;
    font-family: "Poppins", sans-serif;
  }

  .submit-btn {
    background: #495057;
    color: #ffffff;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px 0;
    border-radius: 8px;
    margin-top: 30px;
    font-weight: 700;
    font-size: 20px;
    transition: all 0.2s ease;
    p {
    }
  }
  .submit-btn:hover {
    background: #868e96;
  }

  .form-footer {
    p {
      font-size: 16px;
      margin-left: 20px;
      font-weight: 500;
      font-family: "Poppins", sans-serif;
    }
  }

  @media (max-width: 400px) {
    .form-container {
      width: 350px;
      /* padding: 4rem 4rem 3rem 4rem; */
    }
  }

  .side-content {
    font-family: "Poppins", sans-serif;
    position: absolute;
    background: #868e96;
    right: -40rem;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 8px;
    padding: 20px;
  }
  .side-in-content {
    color: #fafafa;

    .email-and-icon {
      display: flex;
      /* align-items: center; */
      gap: 10px;
    }
    .email-icon {
      font-size: 16px;
    }
    a {
      font-size: 16px;
      color: #fafafa;
    }
  }
  @media (max-width: 1000px) {
    .side-content {
      display: none;
    }
  }
`;

export default Wrapper;
